import { useCallback, useEffect } from "react";
import dayjs from "dayjs";
import useLocalStorage from "../utils/localstorage";

export default function useAuthentication() {
  const [token, setToken] = useLocalStorage("token", {} as { access: string; expired: number; type: string });
  const [phoneNumber, setPhoneNumber] = useLocalStorage("phone-number", "");
  const [customerName, setCustomerName] = useLocalStorage("customer-name", "");

  const check = useCallback(
    async (phone: string, callback: (register: boolean) => void) => {
      return await fetch(process.env.REACT_APP_API_URL + "/api/pos-order/outlet-service/phone/check", {
        body: JSON.stringify({ phone }),
        headers: { Authorization: `${token.type} ${token.access}`, ContentType: "application/json" },
        method: "POST",
      })
        .then((response) => response.json())
        .then((data) => {
          callback(data.result.register);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    [token.access, token.type]
  );

  const request = useCallback(
    async (name: string, phone: string) => {
      return await fetch(process.env.REACT_APP_API_URL + "/api/pos-order/outlet-service/pin/request", {
        body: JSON.stringify({ name, phone, request_type: process.env.REACT_APP_REQUEST_TYPE }),
        headers: { Authorization: `${token.type} ${token.access}`, ContentType: "application/json" },
        method: "POST",
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    [token.access, token.type]
  );

  const handleRequestToken = useCallback(async () => {
    return await fetch(process.env.REACT_APP_API_URL + "/oauth/token", {
      body: JSON.stringify({
        client_id: process.env.REACT_APP_CLIENT_ID,
        client_secret: process.env.REACT_APP_CLIENT_SECRET,
        grant_type: "client_credentials",
        scope: "pos-order",
      }),
      headers: { "Content-Type": "application/json" },
      method: "POST",
    })
      .then((response) => response.json())
      .then((data) => {
        setToken({ access: data.access_token, expired: data.expires_in, type: data.token_type });
      })
      .catch((error) => {
        console.log(error);
      });
  }, [setToken]);

  const resetPhoneNumber = () => setPhoneNumber("");
  const resetCustomerName = () => setCustomerName("");

  useEffect(() => {
    if (dayjs.unix(token?.expired as number).diff(dayjs()) < 1 || token?.expired === undefined) {
      handleRequestToken();
    }
  }, [handleRequestToken, token?.expired]);

  return { handle: { check, request }, phoneNumber, resetPhoneNumber, token, resetCustomerName, customerName, setCustomerName, setPhoneNumber };
}
