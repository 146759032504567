import { useCallback, useState } from "react";
import type { Authentication } from "../types/authentication";
import type { Cart } from "../types/cart";
import type { Checkout, ProductResponse, ServiceResponse } from "../types/checkout";
import useLocalStorage from "../utils/localstorage";

function currentDateFormat(date: Date) {
  let d = new Date(date),
    day = String(d.getDate()),
    month = String(d.getMonth() + 1),
    year = d.getFullYear();
  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;
  return [year, month, day].join("-");
}

export default function useCheckout(authentication: Authentication, cart: Cart, outlet_code: string) {
  const [state, setState] = useLocalStorage("checkout", {} as Checkout);
  const saved_promo_code = JSON.parse(localStorage.getItem("promo-code") || "null");
  const [loading, setLoading] = useState(false);

  const handle = useCallback(
    async (promo_code?: string) => {
      setLoading(true);
      return await fetch(process.env.REACT_APP_API_URL + "/api/pos-order/outlet-service/check", {
        body: JSON.stringify({
          item:
            cart.products.map((product, index) => ({
              id_custom: index,
              id_brand: product.id_brand,
              id_product: product.id,
              product_code: product.code,
              product_name: product.name,
              product_price: product.price,
              product_price_total: (product.quantity || 1) * product.price,
              qty: product.quantity,
            })) || [],
          item_service:
            cart.services.map((service, index) => ({
              booking_date: currentDateFormat(new Date(Date.now())),
              id_brand: service.id_brand,
              id_custom: index,
              id_product: service.id,
              product_code: service.code,
              product_name: service.name,
              product_price: service.price,
              qty: service.quantity,
            })) || [],
          promo_code: saved_promo_code ?? promo_code,
          outlet_code,
          phone: authentication.phoneNumber || null,
        }),
        headers: { Authorization: `${authentication.token.type} ${authentication.token.access}`, ContentType: "application/json" },
        method: "POST",
      })
        .then((response) => response.json())
        .then((data) => {
          setState({
            currency: data.result.currency,
            grandtotal: data.result.grandtotal,
            outlet: {
              name: data.result.outlet.outlet_name,
            },
            payment_detail: data.result.payment_detail,
            products: data.result.item.map((product: ProductResponse) => ({
              code: product.product_code,
              id: product.id_product,
              id_brand: product.id_brand,
              id_custom: product.id_custom,
              name: product.product_name,
              price: product.product_price,
              price_total: product.product_price_total,
              quantity: product.qty,
            })),
            services: data.result.item_service.map((service: ServiceResponse) => ({
              code: service.product_code,
              date: service.booking_date,
              date_display: service.booking_date_display,
              id: service.id_product,
              id_brand: service.id_brand,
              id_custom: service.id_custom,
              name: service.product_name,
              price: service.product_price,
              price_total: service.product_price_total,
              quantity: service.qty,
            })),
            subtotal_products: data.result.subtotal_product,
            subtotal_services: data.result.subtotal_product_service,
          });
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => setLoading(false));
    },
    [authentication.phoneNumber, authentication.token.access, authentication.token.type, cart.products, cart.services, outlet_code, setState]
  );

  const reset = () => setState({} as Checkout);

  return { handle, loading, reset, state };
}
