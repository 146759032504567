import { Fragment, Suspense, useContext } from "react";
import { useParams } from "react-router";
import {
  IonBackButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonImg,
  IonPage,
  IonRow,
  IonSkeletonText,
  IonText,
  IonTitle,
  IonToolbar,
  useIonViewWillEnter,
} from "@ionic/react";
import { arrowBack } from "ionicons/icons";
import QRCode from "react-qr-code";
import useSWR from "swr";
import ApplicationContext from "../Context";
import { Transaction } from "../types/outlet";
import "../styles/views/receipt.css";

export default function Receipt() {
  const { authentication, code, setCode } = useContext(ApplicationContext);
  const params = useParams<{ code: string; receipt_number: string }>();

  const {
    data: transaction,
    error: isError,
    isLoading,
  } = useSWR<Transaction, Error>(
    authentication.token.access !== undefined ? "/api/pos-order/outlet-service/detail-transaction" : null,
    async (url: string) =>
      await fetch(process.env.REACT_APP_API_URL + url, {
        body: JSON.stringify({ transaction_receipt_number: params.receipt_number }),
        headers: { Authorization: `${authentication.token.type} ${authentication.token.access}`, ContentType: "application/json" },
        method: "POST",
      }).then((res) => res.json())
  );

  useIonViewWillEnter(() => {
    setCode(params.code);
  });

  return (
    <IonPage className="view-receipt">
      <IonHeader mode="md">
        <IonToolbar mode="md">
          <IonButtons slot="start">
            <IonBackButton defaultHref={`/${code}`} icon={arrowBack} mode="md" />
          </IonButtons>
          <IonTitle>
            <Suspense fallback={<IonSkeletonText animated={true} />}>
              <IonImg alt={process.env.REACT_APP_NAME} src="assets/images/logo.png" title={process.env.REACT_APP_NAME} />
            </Suspense>
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <IonGrid>
          <IonRow className="ion-align-items-center">
            <IonCol className="ion-no-padding" size="12" sizeLg={transaction?.result.service.length !== 0 ? "9" : "12"}>
              <IonGrid>
                <IonRow>
                  <IonCol className="qr-code-wrapper" size="4" sizeXl="3">
                    {isError || isLoading ? (
                      <IonSkeletonText animated={true} className="qr-code-skeleton" />
                    ) : (
                      <IonCard className="ion-no-margin" mode="md">
                        <IonCardContent mode="md">
                          <QRCode className="qr-code" value={(transaction?.result.transaction_receipt_number as string) || ""} />
                        </IonCardContent>
                      </IonCard>
                    )}
                  </IonCol>
                  <IonCol size="8" sizeXl="9">
                    <IonText mode="md">
                      <h1>{isError || isLoading ? <IonSkeletonText animated={true} /> : transaction?.result.brand.brand_name}</h1>
                    </IonText>
                    <IonText mode="md">
                      <p>
                        {isError || isLoading ? (
                          <Fragment>
                            <IonSkeletonText animated={true} />
                            <IonSkeletonText animated={true} />
                            <IonSkeletonText animated={true} />
                            <IonSkeletonText animated={true} />
                          </Fragment>
                        ) : (
                          transaction?.result.outlet.outlet_address
                        )}
                      </p>
                    </IonText>
                    <table className="ion-margin-top">
                      <tbody>
                        <tr>
                          <td className="ion-text-nowrap">ID Order</td>
                          <td className="ion-padding-start ion-padding-end">:</td>
                          <td style={{ width: isError || isLoading ? "50%" : "100%" }}>
                            {isError || isLoading ? <IonSkeletonText animated={true} /> : transaction?.result.transaction_receipt_number}
                          </td>
                        </tr>
                        <tr>
                          <td className="ion-text-nowrap">Tanggal</td>
                          <td className="ion-padding-start ion-padding-end">:</td>
                          <td style={{ width: isError || isLoading ? "50%" : "100%" }}>
                            {isError || isLoading ? <IonSkeletonText animated={true} /> : transaction?.result.transaction_date_indo}
                          </td>
                        </tr>
                        <tr>
                          <td className="ion-text-nowrap">Name</td>
                          <td className="ion-padding-start ion-padding-end">:</td>
                          <td style={{ width: isError || isLoading ? "50%" : "100%" }}>
                            {isError || isLoading ? <IonSkeletonText animated={true} /> : transaction?.result.customer_name}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCol>
            {transaction?.result.service.length !== 0 ? (
              <IonCol className="ion-no-padding" size="12" sizeLg="3">
                <section className="ion-hide-md-up ion-margin-top">
                  <div className="number">
                    <IonText mode="md">
                      <h2>Nomor Antrian Anda</h2>
                    </IonText>
                    <IonText mode="md">{isError || isLoading ? <IonSkeletonText animated={true} /> : <strong>{transaction?.result.queue}</strong>}</IonText>
                    <IonImg alt="Nomor Antrian Anda" src="assets/icons/ticket.png" title="Nomor Antrian Anda" />
                  </div>
                  <hr className="dashed" />
                  <div className="current">
                    <IonText mode="md">
                      <h3 className="ion-text-capitalize">
                        Sedang
                        <br />
                        Berlangsung
                      </h3>
                    </IonText>
                    <IonImg alt="Sedang Berlangsung" src="assets/icons/queue.png" title="Sedang Berlangsung" />
                    {isError || isLoading ? (
                      <ul>
                        {new Array(10).fill("").map((_, index) => (
                          <li key={index}>
                            <IonSkeletonText animated={true} />
                          </li>
                        ))}
                      </ul>
                    ) : transaction?.result.current_service.length === 0 ? null : (
                      <ul>
                        {transaction?.result.current_service.map((number, index) => (
                          <li key={index}>
                            <strong>{number}</strong>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </section>
                <IonGrid className="queue-desktop">
                  <IonRow>
                    <IonCol size="4" sizeLg="12">
                      <div className="queue">
                        <div className="header">
                          <IonText mode="md">
                            <h2>Nomor Antrian Anda</h2>
                          </IonText>
                          <IonImg alt="Nomor Antrian Anda" src="assets/icons/ticket.png" title="Nomor Antrian Anda" />
                        </div>
                        {isError || isLoading ? (
                          <IonSkeletonText animated={true} className="badge-skeleton" />
                        ) : (
                          <div className="badge ion-text-center">
                            <IonText mode="md">
                              <strong>{transaction?.result.queue}</strong>
                            </IonText>
                          </div>
                        )}
                      </div>
                    </IonCol>
                    <IonCol size="8" sizeLg="12">
                      <div className="current-list">
                        <IonText mode="md">
                          <h3>Sedang Berlangsung</h3>
                        </IonText>
                        <hr className="dashed" />
                        <div className="current">
                          <IonImg alt="Sedang Berlangsung" src="assets/icons/queue.png" title="Sedang Berlangsung" />
                          {isError || isLoading ? (
                            <ul>
                              {new Array(16).fill("").map((_, index) => (
                                <li key={index}>
                                  <IonSkeletonText animated={true} />
                                </li>
                              ))}
                            </ul>
                          ) : transaction?.result.current_service.length === 0 ? null : (
                            <ul>
                              {transaction?.result.current_service.map((number, index) => (
                                <li key={index}>
                                  <strong>{number}</strong>
                                </li>
                              ))}
                            </ul>
                          )}
                        </div>
                      </div>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonCol>
            ) : null}
          </IonRow>
        </IonGrid>
        {isError || isLoading
          ? new Array(2).fill("").map((_, index) => (
              <IonCard className="card-service-skeleton" key={index}>
                <IonCardContent>
                  <IonSkeletonText animated={true} />
                </IonCardContent>
              </IonCard>
            ))
          : transaction?.result.service.map((service, index) => (
              <IonCard key={index} mode="md">
                <IonCardContent mode="md">
                  <div className="title">
                    <IonImg alt={service.product_name} src="assets/icons/barbershop.png" />
                    <IonText mode="md">
                      <h3>Service</h3>
                    </IonText>
                  </div>
                  <table>
                    <tbody>
                      <tr>
                        <td colSpan={3}>Tanggal:</td>
                        <td className="ion-text-end">{service.schedule_date}</td>
                      </tr>
                      <tr>
                        <td colSpan={3}>Jenis Layanan:</td>
                        <td className="ion-text-end">
                          {service.qty} x {service.product_name}
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={4}>
                          <hr className="solid" />
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={3}>
                          <strong>Sub Total</strong>
                        </td>
                        <td className="ion-text-end">
                          <strong>
                            {new Intl.NumberFormat("id-ID", { currency: "IDR", minimumFractionDigits: 0, style: "currency" })
                              .format(service.subtotal)
                              .replace(/^(\D+)/, "$1 ")
                              .replace(/\s+/, " ")}
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </IonCardContent>
              </IonCard>
            ))}
        {isError || isLoading
          ? new Array(2).fill("").map((_, index) => (
              <IonCard className="card-product-skeleton" key={index}>
                <IonCardContent>
                  <IonSkeletonText animated={true} />
                </IonCardContent>
              </IonCard>
            ))
          : transaction?.result.product.map((product, index) => (
              <IonCard key={index} mode="md">
                <IonCardContent mode="md">
                  <div className="title">
                    <IonImg alt={product.product_name} src="assets/icons/spray.png" />
                    <IonText mode="md">
                      <h3>Product</h3>
                    </IonText>
                  </div>
                  <table>
                    <tbody>
                      <tr>
                        <td colSpan={3}>Jenis Produk:</td>
                        <td className="ion-text-end">
                          {product.transaction_product_qty} x {product.product_name}
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={4}>
                          <hr className="solid" />
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={3}>
                          <strong>Sub Total</strong>
                        </td>
                        <td className="ion-text-end">
                          <strong>
                            {new Intl.NumberFormat("id-ID", { currency: "IDR", minimumFractionDigits: 0, style: "currency" })
                              .format(product.transaction_product_subtotal)
                              .replace(/^(\D+)/, "$1 ")
                              .replace(/\s+/, " ")}
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </IonCardContent>
              </IonCard>
            ))}
        <IonCard className="total" mode="md">
          <IonCardContent mode="md">
            <table>
              <tbody>
                {isError || isLoading
                  ? new Array(3).fill("").map((_, index) => (
                      <tr key={index}>
                        <td colSpan={3}>
                          <IonSkeletonText animated={true} style={{ width: "50%" }} />
                        </td>
                        <td>
                          <IonSkeletonText animated={true} />
                        </td>
                      </tr>
                    ))
                  : transaction?.result.payment_detail.map((item, index) => (
                      <tr key={index}>
                        <td colSpan={item.amount === null ? 4 : 3} style={{ color: item.is_discount ? "var(--ion-color-medium)" : "var(--ion-color-dark)" }}>
                          {item.name}
                        </td>
                        {item.amount !== null ? (
                          <td className="ion-text-end" style={{ color: item.is_discount ? "var(--ion-color-danger)" : "var(--ion-color-dark)" }}>
                            {item.amount}
                          </td>
                        ) : null}
                      </tr>
                    ))}
                <tr>
                  <td colSpan={4}>
                    <hr className="dashed" />
                  </td>
                </tr>
                <tr>
                  <td colSpan={3}>
                    <strong>GRAND TOTAL</strong>
                  </td>
                  <td className="ion-text-end">
                    {isError || isLoading ? (
                      <IonSkeletonText animated={true} />
                    ) : (
                      <strong>
                        {new Intl.NumberFormat("id-ID", { currency: "IDR", minimumFractionDigits: 0, style: "currency" })
                          .format(transaction?.result.transaction_grandtotal || 0)
                          .replace(/^(\D+)/, "$1 ")
                          .replace(/\s+/, " ")}
                      </strong>
                    )}
                  </td>
                </tr>
                <tr>
                  <td colSpan={3}>
                    {isError || isLoading ? (
                      <IonSkeletonText animated={true} style={{ width: isError || isLoading ? "50%" : "auto" }} />
                    ) : (
                      transaction?.result.payment_method.text
                    )}
                  </td>
                  <td className="ion-text-end" style={{ width: isError || isLoading ? "30%" : "auto" }}>
                    {isError || isLoading ? <IonSkeletonText animated={true} /> : <strong>{transaction?.result.payment_method.value}</strong>}
                  </td>
                </tr>
              </tbody>
            </table>
          </IonCardContent>
        </IonCard>
      </IonContent>
    </IonPage>
  );
}
