import { useCallback, useState } from "react";
import type { Authentication } from "../types/authentication";
import type { Checkout } from "../types/checkout";
import type { PaymentConfirmation } from "../types/transaction";
import useLocalStorage from "../utils/localstorage";

export default function useTransaction(authentication: Authentication, checkout: Checkout, outlet_code: string) {
  const [paymentConfirmation, setPaymentConfirmation] = useLocalStorage("payment-confirmation", {} as PaymentConfirmation);
  const [idTransaction, setIdTransaction] = useLocalStorage("id-transaction", null as null | number);
  const [loading, setLoading] = useState(false);

  const newTransaction = useCallback(
    async (payment_detail: string, payment_type: string, callback: (id: string, redirect_url?: string) => void) => {
      setLoading(true);
      return await fetch(process.env.REACT_APP_API_URL + "/api/pos-order/outlet-service/new", {
        body: JSON.stringify({
          item:
            checkout.products.map((product) => ({
              id_brand: product.id_brand,
              id_custom: product.id_custom,
              id_product: product.id,
              product_code: product.code,
              product_name: product.name,
              product_price: product.price,
              product_price_total: product.price_total,
              qty: product.quantity,
            })) || [],
          item_service:
            checkout.services.map((service) => ({
              booking_date: service.date,
              id_brand: service.id_brand,
              id_custom: service.id_custom,
              id_product: service.id,
              product_code: service.code,
              product_name: service.name,
              product_price: service.price,
              qty: service.quantity,
            })) || [],
          outlet_code,
          payment_detail,
          payment_type,
          phone: authentication.phoneNumber || null,
        }),
        headers: { Authorization: `${authentication.token.type} ${authentication.token.access}`, ContentType: "application/json" },
        method: "POST",
      })
        .then((response) => response.json())
        .then((data) => {
          const idTransaction =
            data.result.redirect_url !== undefined ? data.result.transaction_data.transaction_details.id_transaction : data.result.id_transaction;
          setIdTransaction(idTransaction);
          callback(idTransaction, data.result.redirect_url);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => setLoading(false));
    },
    [authentication.phoneNumber, authentication.token.access, authentication.token.type, checkout.products, checkout.services, outlet_code, setIdTransaction]
  );

  const handlePaymentConfirmation = useCallback(
    async (id: string, payment_detail: string, payment_type: string, redirect: (url: string) => void) => {
      setLoading(true);
      return await fetch(process.env.REACT_APP_API_URL + "/api/pos-order/outlet-service/confirm", {
        body: JSON.stringify({ id, outlet_code, payment_detail, payment_type, phone: authentication.phoneNumber || null }),
        headers: { Authorization: `${authentication.token.type} ${authentication.token.access}`, ContentType: "application/json" },
        method: "POST",
      })
        .then((response) => response.json())
        .then((data) => {
          setPaymentConfirmation(data.result);
          redirect(data.result.redirect_url);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => setLoading(false));
    },
    [authentication.phoneNumber, authentication.token.access, authentication.token.type, outlet_code, setPaymentConfirmation]
  );

  const reset = () => {
    setPaymentConfirmation({} as PaymentConfirmation);
    setIdTransaction(null);
  };

  return {
    handle: {
      new: newTransaction,
      paymentConfirmation: handlePaymentConfirmation,
      reset,
    },
    id: idTransaction,
    loading,
    paymentConfirmation,
  };
}
