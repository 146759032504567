import { useContext } from "react";
import useSWRInfinite from "swr/infinite";
import ApplicationContext from "../Context";
import type { ProductWithServicesDataResponse, ServicesDataResponse } from "../types/history-transaction";

export default function useHistoryTransaction(outlet_code: string) {
  const { authentication } = useContext(ApplicationContext);

  const {
    data: services,
    error: servicesError,
    isLoading: servicesLoading,
    isValidating: servicesValidating,
    mutate: servicesMutate,
    setSize: servicesSetSize,
    size: servicesSize,
  } = useSWRInfinite<ServicesDataResponse, Error>(
    (index) => `/api/pos-order/outlet-service/list-transaction?page=${index + 1}`,
    async (url: string) =>
      await fetch(process.env.REACT_APP_API_URL + url, {
        body: JSON.stringify({ outlet_code }),
        headers: { Authorization: `${authentication.token.type} ${authentication.token.access}`, ContentType: "application/json" },
        method: "POST",
      }).then((res) => res.json())
  );

  const {
    data: productsWithServices,
    error: productsWithServicesError,
    isLoading: productsWithServicesLoading,
    isValidating: productsWithServicesValidating,
    mutate: productsWithServicesMutate,
    setSize: productsWithServicesSetSize,
    size: productsWithServicesSize,
  } = useSWRInfinite<ProductWithServicesDataResponse, Error>(
    (index) => `/api/pos-order/outlet-service/list-trans-product?page=${index + 1}`,
    async (url: string) =>
      await fetch(process.env.REACT_APP_API_URL + url, {
        body: JSON.stringify({ outlet_code }),
        headers: { Authorization: `${authentication.token.type} ${authentication.token.access}`, ContentType: "application/json" },
        method: "POST",
      }).then((res) => res.json())
  );

  const servicesData = services ? [].concat(...(services as Array<never>)) : ([] as Array<ServicesDataResponse>);
  const servicesEmpty = services?.[0]?.result.data.length === 0;
  const servicesLoadingMore = servicesLoading || (servicesSize > 0 && services && typeof services[servicesSize - 1] === "undefined");
  const servicesReachingEnd =
    services?.[0]?.result.data.length === 0 || (services && services[services.length - 1]?.result.data.length < services[servicesSize - 1]?.result.per_page);
  const servicesRefreshing = servicesValidating && services && services.length === servicesSize;
  const servicesItems = services ? services[servicesSize - 1]?.result.per_page : 10;

  const productsWithServicesData = productsWithServices ? [].concat(...(productsWithServices as Array<never>)) : ([] as Array<ProductWithServicesDataResponse>);
  const productsWithServicesEmpty = productsWithServices?.[0]?.result.data.length === 0;
  const productsWithServicesLoadingMore =
    productsWithServicesLoading ||
    (productsWithServicesSize > 0 && productsWithServices && typeof productsWithServices[productsWithServicesSize - 1] === "undefined");
  const productsWithServicesReachingEnd =
    productsWithServices?.[0]?.result.data.length === 0 ||
    (productsWithServices &&
      productsWithServices[productsWithServices.length - 1]?.result.data.length < productsWithServices[productsWithServicesSize - 1]?.result.per_page);
  const productsWithServicesRefreshing = productsWithServicesValidating && productsWithServices && productsWithServices.length === productsWithServicesSize;
  const productsWithServicesItems = productsWithServices ? productsWithServices[productsWithServicesSize - 1]?.result.per_page : 10;

  return {
    services: {
      data: servicesData,
      isEmpty: servicesEmpty,
      isError: servicesError,
      isLoading: servicesLoadingMore,
      isReachingEnd: servicesReachingEnd,
      isRefreshing: servicesRefreshing,
      items: servicesItems,
      mutate: servicesMutate,
      setSize: servicesSetSize,
      size: servicesSize,
    },
    productsWithServices: {
      data: productsWithServicesData,
      isEmpty: productsWithServicesEmpty,
      isError: productsWithServicesError,
      isLoading: productsWithServicesLoadingMore,
      isReachingEnd: productsWithServicesReachingEnd,
      isRefreshing: productsWithServicesRefreshing,
      items: productsWithServicesItems,
      mutate: productsWithServicesMutate,
      setSize: productsWithServicesSetSize,
      size: productsWithServicesSize,
    },
  };
}
