import { useContext } from "react";
import useSWR from "swr";
import ApplicationContext from "../Context";
import type { Outlet, Queues } from "../types/outlet";

export default function useOutlet(outlet_code: string) {
  const { authentication } = useContext(ApplicationContext);

  const {
    data: outlet,
    error: outletError,
    isLoading: outletLoading,
  } = useSWR<Outlet, Error>(
    "/api/pos-order/outlet-service/home",
    async (url: string) =>
      await fetch(process.env.REACT_APP_API_URL + url, {
        body: JSON.stringify({ outlet_code }),
        headers: { Authorization: `${authentication.token.type} ${authentication.token.access}`, ContentType: "application/json" },
        method: "POST",
      }).then((res) => res.json()),
    { refreshInterval: 20000 }
  );

  const {
    data: queues,
    error: queuesError,
    isLoading: queuesLoading,
  } = useSWR<Queues, Error>(
    "/api/pos-order/outlet-service/list",
    async (url: string) =>
      await fetch(process.env.REACT_APP_API_URL + url, {
        body: JSON.stringify({ outlet_code }),
        headers: { Authorization: `${authentication.token.type} ${authentication.token.access}`, ContentType: "application/json" },
        method: "POST",
      }).then((res) => res.json()),
    { refreshInterval: 15000 }
  );

  return {
    outlet: {
      code: outlet?.result.outlet.outlet_code || [],
      hairstylists:
        outlet?.result.available_hs.map((hairstylist) => ({
          id: hairstylist.id_user_hair_stylist,
          name: hairstylist.name,
          nickname: hairstylist.nickname,
          shift_time: hairstylist.shift_time,
          photo_url: hairstylist.photo,
          status: hairstylist.available_status,
          current_service: hairstylist.current_service,
          end_shift: hairstylist.end_shift,
          order: hairstylist.order,
        })) || [],
      id: outlet?.result.outlet.id_outlet,
      isError: outletError,
      isLoading: outletLoading,
      name: outlet?.result.outlet.outlet_name,
      products:
        outlet?.result.products.map((product) => ({
          code: product.product_code,
          description: product.product_description,
          id: product.id_product,
          id_brand: product.id_brand,
          name: product.product_name,
          photo_url: product.photo,
          price: product.product_price,
          status: product.product_stock_status,
          stock: product.qty_stock,
          type: product.product_type,
        })) || [],
      services:
        outlet?.result.product_services.map((service) => ({
          code: service.product_code,
          description: service.product_description,
          id_brand: service.id_brand,
          id: service.id_product,
          name: service.product_name,
          photo_url: service.photo,
          price: service.product_price,
          status: service.product_stock_status,
          type: service.product_type,
        })) || [],
    },
    queues: {
      currents: queues?.result.current_cust || [],
      isError: queuesError,
      isLoading: queuesLoading,
      orders: queues?.result.order_list || [],
      waitings: queues?.result.waiting || [],
    },
  };
}
