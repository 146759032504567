import { Suspense, useContext, useState } from "react";
import { useHistory } from "react-router";
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonImg,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  IonRow,
  IonSkeletonText,
  IonSpinner,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { arrowBack } from "ionicons/icons";
import useSWR from "swr";
import ApplicationContext from "../Context";
import { Promo } from "../types/outlet";
import "../styles/views/promo-code.css";

export default function PromoCode() {
  const { authentication, checkout, code, promo } = useContext(ApplicationContext);
  const history = useHistory();
  const [selectedPromoCode, setSelectedPromoCode] = useState<string>(promo.code);
  const {
    data,
    error: isError,
    isLoading,
  } = useSWR<Promo, Error>(
    "/api/pos-order/outlet-service/list-promo",
    async (url: string) =>
      await fetch(process.env.REACT_APP_API_URL + url, {
        body: JSON.stringify({
          item: checkout.state.products.map((product) => ({
            id_brand: product.id_brand,
            id_custom: product.id_custom,
            id_product: product.id,
            product_code: product.code,
            product_name: product.name,
            product_price: product.price,
            product_price_total: product.price_total,
            qty: product.quantity,
          })),
          item_service: checkout.state.services.map((service) => ({
            booking_date: service.date,
            id_brand: service.id_brand,
            id_custom: service.id_custom,
            id_product: service.id,
            product_code: service.code,
            product_name: service.name,
            product_price: service.price,
            qty: service.quantity,
          })),
          outlet_code: code,
          phone: authentication.phoneNumber || null,
        }),
        headers: { Authorization: `${authentication.token.type} ${authentication.token.access}`, ContentType: "application/json" },
        method: "POST",
      }).then((res) => res.json())
  );

  const handleSubmitPromoCode = async () => {
    await promo.handle.use(selectedPromoCode);
    history.goBack();
    checkout.handle(selectedPromoCode);
  };

  return (
    <IonPage className="view-promo-code">
      <IonHeader mode="md">
        <IonToolbar mode="md">
          <IonButtons slot="start">
            <IonBackButton defaultHref={`/${code}/confirmation-order`} icon={arrowBack} mode="md" />
          </IonButtons>
          <IonTitle>
            <Suspense fallback={<IonSkeletonText animated={true} />}>
              <IonImg alt={process.env.REACT_APP_NAME} src="assets/images/logo.png" title={process.env.REACT_APP_NAME} />
            </Suspense>
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <IonGrid>
          <IonRow className="ion-align-items-end">
            <IonCol size="12" sizeSm="9">
              <IonLabel mode="md">
                <label htmlFor="promo-code">KODE PROMO</label>
              </IonLabel>
              <IonInput
                autofocus
                id="promo-code"
                mode="md"
                name="promo-code"
                onIonChange={(e) => setSelectedPromoCode(e.detail.value as string)}
                style={{ minHeight: "2.875rem" }}
                tabIndex={-1}
                value={selectedPromoCode}
              />
            </IonCol>
            <IonCol size="12" sizeSm="3" style={{ "--ion-grid-column-padding": 0 }}>
              <IonButton
                color="dark"
                disabled={selectedPromoCode.length <= 0 || promo.loading}
                expand="block"
                mode="md"
                onClick={handleSubmitPromoCode}
                strong={true}
              >
                {promo.loading ? <IonSpinner className="ion-margin-end" name="crescent" slot="start" /> : null}
                Masukkan
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
        <hr className="dashed" />
        <IonGrid>
          <IonRow>
            {isError || isLoading ? (
              new Array(12).fill("").map((_, index) => (
                <IonCol key={index} size="12" sizeMd="6" style={{ "--ion-grid-column-padding": "0.5rem" }}>
                  <IonSkeletonText animated={true} />
                </IonCol>
              ))
            ) : data?.result?.length !== 0 ? (
              data?.result?.map((item, index) => (
                <IonCol key={index} size="12" sizeMd="6" style={{ "--ion-grid-column-padding": "0.5rem" }}>
                  <IonItem
                    button
                    detail={false}
                    disabled={selectedPromoCode === item.promo_code}
                    lines="none"
                    mode="md"
                    onClick={() => setSelectedPromoCode(item.promo_code)}
                  >
                    <IonLabel className="ion-no-margin ion-text-wrap" mode="md">
                      <IonText mode="md">
                        <h3>
                          {item.promo_code} ({item.promo_title})
                        </h3>
                      </IonText>
                      <IonText mode="md">
                        <p>{item.promo_description}</p>
                      </IonText>
                    </IonLabel>
                  </IonItem>
                </IonCol>
              ))
            ) : (
              <IonCol className="empty" size="12">
                <IonImg alt="Kode Promo tidak tersedia" src="assets/icons/promo_code_empty.png" title="Kode Promo tidak tersedia" />
                <IonText className="ion-text-center">
                  <p>Kode Promo tidak tersedia</p>
                </IonText>
              </IonCol>
            )}
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
}
