import { useContext, useEffect, useRef, useState, Suspense } from "react";
import { useHistory, useParams } from "react-router";
import {
  IonButton,
  IonButtons,
  IonChip,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonImg,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonPage,
  IonRow,
  IonSegment,
  IonSegmentButton,
  IonSkeletonText,
  IonSpinner,
  IonText,
  IonThumbnail,
  IonTitle,
  IonToolbar,
  useIonViewWillEnter,
} from "@ionic/react";
import { closeOutline, qrCodeOutline } from "ionicons/icons";
import QRCode from "react-qr-code";
import { EffectFade } from "swiper";
import { Swiper, SwiperRef, SwiperSlide } from "swiper/react";
import ApplicationContext from "../Context";
import CartItems from "../components/CartItems";
import CustomerList from "../components/CustomerList";
import Hairstylists from "../components/Hairstylists";
import useHistoryTransaction from "../hooks/history-transaction";
import useOutlet from "../hooks/outlet";
import useLocalStorage from "../utils/localstorage";
import "swiper/css";
import "swiper/css/effect-fade";
import "../styles/views/home.css";

const tabs = [
  { id: 0, name: "Services", source_icon: "assets/icons/barbershop.png", value: "services" },
  { id: 1, name: "Products", source_icon: "assets/icons/spray.png", value: "products" },
];

export default function Home() {
  const { authentication, cart, checkout, code, setCode, transaction } = useContext(ApplicationContext);
  const history = useHistory();
  const params = useParams<{ code: string }>();

  const [tabActive, setTabActive] = useLocalStorage<"products" | "services">("tab-active", "services");
  const [tabModalActive, setTabModalActive] = useLocalStorage<"services" | "products">("tab-modal-active", "services");

  const inputElement = useRef<HTMLIonInputElement>(null);
  const modal = useRef<HTMLIonModalElement>(null);
  const page = useRef(null);
  const swiperHome = useRef<SwiperRef>(null);
  const swiperModalHome = useRef<SwiperRef>(null);

  const [presentingElement, setPresentingElement] = useState<HTMLElement | null>(null);

  const { productsWithServices, services } = useHistoryTransaction(code);
  const { outlet, queues } = useOutlet(code);

  const handleDetailTransaction = (receiptNumber: string) => {
    modal.current?.dismiss();
    history.push(`/${code}/receipt/${receiptNumber}`);
  };

  useIonViewWillEnter(() => {
    authentication.resetPhoneNumber();
    authentication.setCustomerName("");
    localStorage.setItem("checkout", JSON.stringify({}));
    transaction.handle.reset();
    setCode(params.code);
  });

  useEffect(() => {
    setPresentingElement(page.current);
  }, []);

  return (
    <IonPage className="view-home" ref={page}>
      <IonHeader mode="md">
        <IonToolbar mode="md">
          <IonTitle>
            <Suspense fallback={<IonSkeletonText animated={true} />}>
              <IonImg alt={process.env.REACT_APP_NAME} src="assets/images/logo.png" title={process.env.REACT_APP_NAME} />
            </Suspense>
          </IonTitle>
          <IonTitle slot="end">
            <h1>{outlet.name ?? <IonSkeletonText animated={true} />}</h1>
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <IonGrid>
          <IonRow>
            <IonCol size="12" sizeMd="8">
              <IonSegment onIonChange={(event) => setTabActive(event.detail.value as "products" | "services")} mode="md" value={tabActive}>
                {tabs.map((segment, index) => (
                  <IonSegmentButton key={index} layout="icon-start" mode="md" onClick={() => swiperHome.current?.swiper.slideTo(index)} value={segment.value}>
                    <IonImg alt={segment.name} src={segment.source_icon} title={segment.name} />
                    <IonLabel mode="md">{segment.name}</IonLabel>
                  </IonSegmentButton>
                ))}
              </IonSegment>
              <Swiper
                allowTouchMove={false}
                autoHeight={true}
                effect="fade"
                initialSlide={tabs.find((tab) => tab.value === tabActive)?.id}
                modules={[EffectFade]}
                mousewheel={false}
                observer={true}
                observeParents={true}
                ref={swiperHome}
              >
                <SwiperSlide>
                  <IonList lines="none" mode="md">
                    <CartItems
                      isLoading={outlet.isLoading}
                      isError={outlet.isError}
                      items={outlet.services?.map((service) => ({
                        ...service,
                        checkbox: {
                          checked: cart.value.serviceChecked(service),
                          disabled: service.status === "Sold Out",
                          disabledText: "Layanan tidak tersedia",
                          onChange: (event) => {
                            if (event.detail.checked) cart.context.addService(service);
                            else cart.context.removeService(service);
                          },
                        },
                        decrease: {
                          disabled: cart.value.serviceQuantity(service) <= 0,
                          onClick: () => cart.context.decreaseService(service),
                        },
                        increase: {
                          disabled: service.status === "Sold Out",
                          onClick: () => cart.context.increaseService(service),
                        },
                        input: {
                          disabled: cart.value.serviceQuantity(service) <= 0,
                          onInput: (event: Event) => {
                            const value = (event.target as HTMLIonInputElement).value as string;
                            const filteredValue = value.replace(/[^0-9]+/g, "");
                            if (inputElement.current !== null) {
                              inputElement.current.value = filteredValue;
                            }
                            cart.context.setServiceQuantity(service, Number(filteredValue));
                          },
                          value: cart.value.serviceQuantity(service),
                        },
                      }))}
                      empty={{ iconSource: "assets/icons/service_empty.png", title: "Maaf, service belum tersedia." }}
                      numberOfPreloaders={3}
                    />
                  </IonList>
                </SwiperSlide>
                <SwiperSlide>
                  <IonList lines="none" mode="md">
                    <CartItems
                      isLoading={outlet.isLoading}
                      isError={outlet.isError}
                      items={outlet.products?.map((product) => ({
                        ...product,
                        checkbox: {
                          checked: cart.value.productChecked(product),
                          disabled: product.stock === 0,
                          disabledText: "Produk tidak tersedia",
                          onChange: (event) => {
                            if (event.detail.checked) cart.context.addProduct(product);
                            else cart.context.removeProduct(product);
                          },
                        },
                        decrease: {
                          disabled: cart.value.productQuantity(product) <= 0,
                          onClick: () => cart.context.decreaseProduct(product),
                        },
                        increase: {
                          disabled: cart.value.productQuantity(product) >= product.stock,
                          onClick: () => cart.context.increaseProduct(product),
                        },
                        input: {
                          disabled: cart.value.productQuantity(product) <= 0 || cart.value.productQuantity(product) >= product.stock,
                          onInput: (event: Event) => {
                            const value = (event.target as HTMLIonInputElement).value as string;
                            const filteredValue = value.replace(/[^0-9]+/g, "");
                            if (inputElement.current !== null) {
                              inputElement.current.value = filteredValue;
                            }
                            cart.context.setProductQuantity(product, Number(filteredValue));
                          },
                          value: cart.value.productQuantity(product),
                        },
                      }))}
                      empty={{ iconSource: "assets/icons/product_empty.png", title: "Maaf, produk tidak tersedia." }}
                      numberOfPreloaders={3}
                    />
                  </IonList>
                </SwiperSlide>
              </Swiper>
              <IonGrid className="ion-no-padding submit">
                <IonRow className="ion-justify-content-center">
                  <IonCol size="12">
                    <hr className="dashed" />
                  </IonCol>
                  <IonCol size="12" sizeSm="6">
                    <IonButton
                      color="dark"
                      disabled={cart.value.disabledCheckout || checkout.loading}
                      expand="block"
                      mode="md"
                      onClick={async () => {
                        await checkout.handle();
                        history.push(`/${code}/confirmation-order`);
                      }}
                      strong={true}
                    >
                      {checkout.loading ? <IonSpinner className="ion-margin-end" name="crescent" slot="start" /> : null}
                      Checkout
                    </IonButton>
                  </IonCol>
                  <IonCol size="12">
                    <hr className="solid" />
                  </IonCol>
                </IonRow>
              </IonGrid>
              <Hairstylists
                hairstylists={outlet.hairstylists}
                isError={outlet.isError}
                isLoading={outlet.isLoading}
                empty={{ iconSource: "assets/icons/hairstylist_empty.png", title: "Tidak ada kehadiran hairstylist" }}
                numberOfPreloaders={4}
                title="Hairstylists"
              />
            </IonCol>
            <IonCol className="sticky-top" size="12" sizeMd="4">
              <div className="customers">
                <CustomerList
                  action={
                    <IonButton className="ion-no-margin" color="dark" fill="clear" id="open-modal-home" mode="md" shape="round" size="small">
                      <IonIcon icon={qrCodeOutline} slot="icon-only" size="small" />
                    </IonButton>
                  }
                  isError={queues.isError}
                  isLoading={queues.isLoading}
                  list={queues.currents}
                  empty={{ iconSource: "assets/icons/barber_chair.png", title: "No Queues" }}
                  numberOfPreloaders={8}
                  title="Current Customer"
                />
                <CustomerList
                  isError={queues.isError}
                  isLoading={queues.isLoading}
                  list={queues.waitings}
                  empty={{ iconSource: "assets/icons/barber_chair.png", title: "No Queues" }}
                  numberOfPreloaders={8}
                  title="Waiting List"
                />
                <CustomerList
                  isError={queues.isError}
                  isLoading={queues.isLoading}
                  list={queues.orders}
                  empty={{ iconSource: "assets/icons/barber_chair.png", title: "No Queues" }}
                  numberOfPreloaders={8}
                  title="Order List"
                />
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonModal id="modal-home" mode="md" presentingElement={presentingElement!} ref={modal} trigger="open-modal-home">
          <IonHeader mode="md">
            <IonToolbar mode="md">
              <IonTitle className="ion-text-center">
                <h2>Order Berlangsung</h2>
              </IonTitle>
              <IonButtons slot="end">
                <IonButton color="dark" fill="clear" id="close-modal" mode="md" onClick={() => modal.current?.dismiss()} shape="round">
                  <IonIcon icon={closeOutline} mode="md" slot="icon-only" />
                </IonButton>
              </IonButtons>
            </IonToolbar>
            <hr className="dashed" />
            <IonSegment onIonChange={(event) => setTabModalActive(event.detail.value as "services" | "products")} mode="md" value={tabModalActive}>
              {tabs.map((segment, index) => (
                <IonSegmentButton
                  key={index}
                  layout="icon-start"
                  mode="md"
                  onClick={() => swiperModalHome.current?.swiper.slideTo(index)}
                  value={segment.value}
                >
                  <IonImg alt={segment.name} src={segment.source_icon} title={segment.name} />
                  <IonLabel mode="md">{segment.name}</IonLabel>
                </IonSegmentButton>
              ))}
            </IonSegment>
          </IonHeader>
          <IonContent>
            <Swiper
              allowTouchMove={false}
              autoHeight={true}
              initialSlide={tabs.find((tab) => tab.value === tabModalActive)?.id}
              mousewheel={false}
              observer={true}
              observeParents={true}
              ref={swiperModalHome}
            >
              <SwiperSlide>
                <IonGrid className="ion-padding">
                  <IonRow>
                    {services.isError || services.isRefreshing ? (
                      new Array(services.items).fill("").map((_, index) => (
                        <IonCol className="skeleton" key={index} size="12" sizeMd="6">
                          <IonSkeletonText animated={true} />
                        </IonCol>
                      ))
                    ) : services.isEmpty ? (
                      <IonCol className="empty" size="12">
                        <IonImg alt="Tidak ada antrian yang berlangsung" src="assets/icons/service_empty.png" title="Tidak ada antrian yang berlangsung" />
                        <IonText className="ion-text-center">
                          <p>Tidak ada antrian yang berlangsung</p>
                        </IonText>
                      </IonCol>
                    ) : (
                      services.data.map((service) =>
                        service.result.data.map((transaction, index) => (
                          <IonCol key={index} size="12" sizeMd="6" style={{ position: "relative" }}>
                            <IonChip mode="md" onClick={() => handleDetailTransaction(transaction.transaction_receipt_number)}>
                              {transaction.queue}
                            </IonChip>
                            <IonItem
                              button
                              detail={false}
                              lines="none"
                              mode="md"
                              onClick={() => handleDetailTransaction(transaction.transaction_receipt_number)}
                            >
                              <IonThumbnail slot="start">
                                <QRCode className="qr-code" size={80} value={transaction.transaction_receipt_number || ""} viewBox={`0 0 80 80`} />
                              </IonThumbnail>
                              <IonLabel>
                                <ul>
                                  <li>
                                    ID: <div className="ion-margin-start ion-text-end">{transaction.transaction_receipt_number}</div>
                                  </li>
                                  <li>
                                    Tanggal: <div className="ion-margin-start ion-text-end">{transaction.transaction_date_indo}</div>
                                  </li>
                                  <li>
                                    Nama: <div className="ion-margin-start ion-text-end">{transaction.customer_name}</div>
                                  </li>
                                  <li>
                                    Order: <div className="ion-margin-start ion-text-end">{transaction.product}</div>
                                  </li>
                                </ul>
                              </IonLabel>
                            </IonItem>
                          </IonCol>
                        ))
                      )
                    )}
                  </IonRow>
                </IonGrid>
                <IonInfiniteScroll
                  onIonInfinite={(event) => {
                    services.setSize(services.size + 1);
                    event.target.complete();
                  }}
                >
                  {services.isReachingEnd ? null : (
                    <IonInfiniteScrollContent>
                      <IonSpinner name="crescent" />
                    </IonInfiniteScrollContent>
                  )}
                </IonInfiniteScroll>
              </SwiperSlide>
              <SwiperSlide>
                <IonGrid className="ion-padding">
                  <IonRow>
                    {productsWithServices.isError || productsWithServices.isRefreshing ? (
                      new Array(productsWithServices.items).fill("").map((_, index) => (
                        <IonCol className="skeleton" key={index} size="12" sizeMd="6">
                          <IonSkeletonText animated={true} />
                        </IonCol>
                      ))
                    ) : productsWithServices.isEmpty ? (
                      <IonCol className="empty" size="12">
                        <IonImg alt="Tidak ada antrian yang berlangsung" src="assets/icons/service_empty.png" title="Tidak ada antrian yang berlangsung" />
                        <IonText className="ion-text-center">
                          <p>Tidak ada antrian yang berlangsung</p>
                        </IonText>
                      </IonCol>
                    ) : (
                      productsWithServices.data.map((service) =>
                        service.result.data.map((transaction, index) => (
                          <IonCol key={index} size="12" sizeMd="6" style={{ position: "relative" }}>
                            <IonItem
                              button
                              detail={false}
                              lines="none"
                              mode="md"
                              onClick={() => handleDetailTransaction(transaction.transaction_receipt_number)}
                            >
                              <IonThumbnail slot="start">
                                <QRCode className="qr-code" size={100} value={transaction.transaction_receipt_number || ""} viewBox={`0 0 100 100`} />
                              </IonThumbnail>
                              <IonLabel>
                                <ul>
                                  <li>
                                    ID: <div className="ion-margin-start ion-text-end">{transaction.transaction_receipt_number}</div>
                                  </li>
                                  <li>
                                    Tanggal: <div className="ion-margin-start ion-text-end">{transaction.transaction_date_indo}</div>
                                  </li>
                                  <li>
                                    Nama: <div className="ion-margin-start ion-text-end">{transaction.customer_name}</div>
                                  </li>
                                  <li>
                                    Order:
                                    <div className="ion-margin-start ion-text-end">
                                      {transaction.product.map((item, index) => (
                                        <span key={index}>
                                          {item.product_name} (x{item.transaction_product_qty}){index < transaction.product.length - 1 ? ", " : ""}
                                        </span>
                                      ))}
                                    </div>
                                  </li>
                                </ul>
                              </IonLabel>
                            </IonItem>
                          </IonCol>
                        ))
                      )
                    )}
                  </IonRow>
                </IonGrid>
                <IonInfiniteScroll
                  onIonInfinite={(event) => {
                    productsWithServices.setSize(productsWithServices.size + 1);
                    event.target.complete();
                  }}
                >
                  {productsWithServices.isReachingEnd ? null : (
                    <IonInfiniteScrollContent>
                      <IonSpinner name="crescent" />
                    </IonInfiniteScrollContent>
                  )}
                </IonInfiniteScroll>
              </SwiperSlide>
            </Swiper>
          </IonContent>
        </IonModal>
      </IonContent>
    </IonPage>
  );
}
