import { Suspense, useContext, useState } from "react";
import { useHistory, useParams } from "react-router";
import {
  IonAvatar,
  IonBackButton,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonImg,
  IonItem,
  IonLabel,
  IonPage,
  IonRadio,
  IonRadioGroup,
  IonRow,
  IonSkeletonText,
  IonSpinner,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { arrowBack } from "ionicons/icons";
import useSWR from "swr";
import ApplicationContext from "../Context";
import type { Payments } from "../types/outlet";
import "../styles/views/payment-method.css";

export default function PaymentMethod() {
  const { authentication, checkout, transaction } = useContext(ApplicationContext);
  const history = useHistory();
  const { code } = useParams<{ code: string }>();
  const [selectedPaymentCode, setSelectedPaymentCode] = useState<string>();

  const {
    data: payments,
    error: isError,
    isLoading,
  } = useSWR<Payments, Error>(
    "/api/pos-order/outlet-service/available-payment",
    async (url: string) =>
      await fetch(process.env.REACT_APP_API_URL + url, {
        body: JSON.stringify({ outlet_code: code, pos_order: 1, show_all: 1 }),
        headers: { Authorization: `${authentication.token.type} ${authentication.token.access}`, ContentType: "application/json" },
        method: "POST",
      }).then((res) => res.json())
  );

  const paymentDetail = payments?.result.find((payment) => payment.code === selectedPaymentCode)?.payment_method as string;
  const paymentType = payments?.result?.find((payment) => payment.code === selectedPaymentCode)?.payment_gateway as string;

  return (
    <IonPage className="view-payment-method">
      <IonHeader mode="md">
        <IonToolbar mode="md">
          <IonButtons slot="start">
            <IonBackButton defaultHref={`/${code}/confirmation-order`} icon={arrowBack} mode="md" />
          </IonButtons>
          <IonTitle>
            <Suspense fallback={<IonSkeletonText animated={true} />}>
              <IonImg alt={process.env.REACT_APP_NAME} src="assets/images/logo.png" title={process.env.REACT_APP_NAME} />
            </Suspense>
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <IonGrid>
          <IonRow>
            <IonCol size="12">
              <IonText mode="md">
                <h2>PILIH METODE PEMBAYARAN</h2>
              </IonText>
              <IonText className="total">
                <span>Total</span>
                <h3 className="ion-no-margin">
                  {new Intl.NumberFormat("id-ID", { currency: "IDR", minimumFractionDigits: 0, style: "currency" })
                    .format(checkout.state.grandtotal)
                    .replace(/^(\D+)/, "$1 ")
                    .replace(/\s+/, " ")}
                </h3>
              </IonText>
              <hr className="dashed" />
              <IonRadioGroup onIonChange={(e) => setSelectedPaymentCode(e.detail.value)} value={selectedPaymentCode}>
                <IonGrid className="ion-no-padding">
                  <IonRow>
                    <IonCol size="12" sizeSm="6">
                      {isError || isLoading
                        ? new Array(10).fill("").map((_, index) => (
                            <IonItem className="ion-no-padding" key={index} lines="none" mode="md">
                              <IonSkeletonText animated={true} slot="start" style={{ "--border-radius": "50%", height: "1.5rem", width: "1.5rem" }} />
                              <IonAvatar slot="start">
                                <IonSkeletonText animated={true} />
                              </IonAvatar>
                              <IonLabel mode="md">
                                <IonSkeletonText animated={true} />
                              </IonLabel>
                            </IonItem>
                          ))
                        : payments?.result
                            .filter((payment) => payment.code !== "cash")
                            .map((payment, index) => (
                              <IonItem className="ion-no-padding" key={index} lines="none" mode="md">
                                <IonRadio aria-label={payment.code} color="dark" mode="md" slot="start" value={payment.code} />
                                <IonAvatar slot="start">
                                  <IonImg alt={payment.text} src={payment.logo} />
                                </IonAvatar>
                                <IonLabel mode="md">
                                  <strong>{payment.text}</strong>
                                </IonLabel>
                              </IonItem>
                            ))}
                    </IonCol>
                    <IonCol size="12" sizeSm="6">
                      {isError || isLoading
                        ? new Array(1).fill("").map((_, index) => (
                            <IonItem className="ion-no-padding" key={index} lines="none" mode="md">
                              <IonSkeletonText animated={true} slot="start" style={{ "--border-radius": "50%", height: "1.5rem", width: "1.5rem" }} />
                              <IonAvatar slot="start">
                                <IonSkeletonText animated={true} />
                              </IonAvatar>
                              <IonLabel mode="md">
                                <IonSkeletonText animated={true} />
                              </IonLabel>
                            </IonItem>
                          ))
                        : payments?.result
                            .filter((payment) => payment.code === "cash")
                            .map((payment, index) => (
                              <IonItem className="ion-no-padding" key={index} lines="none" mode="md">
                                <IonRadio aria-label={payment.code} color="dark" mode="md" slot="start" value={payment.code} />
                                <IonAvatar slot="start">
                                  <IonImg alt={payment.text} src={payment.logo} title={payment.text} />
                                </IonAvatar>
                                <IonLabel className="ion-no-margin" mode="md">
                                  <strong>{payment.text}</strong>
                                </IonLabel>
                              </IonItem>
                            ))}
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonRadioGroup>
              <hr className="dashed" />
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonGrid className="submit">
          <IonRow className="ion-justify-content-center">
            <IonCol size="12" sizeSm="6" sizeMd="4">
              <IonButton
                color="dark"
                disabled={selectedPaymentCode === undefined || transaction.loading}
                expand="block"
                onClick={async () => {
                  await transaction.handle.new(paymentDetail, paymentType, async (id, redirect_url) => {
                    if (redirect_url !== undefined) {
                      window.location.replace(redirect_url);
                    } else {
                      history.replace(`/${code}/qr-code?id_transaction=${id}`);
                    }
                  });
                }}
                mode="md"
                strong={true}
              >
                {transaction.loading ? <IonSpinner className="ion-margin-end" name="crescent" slot="start" /> : null}
                Bayar
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
}
