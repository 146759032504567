import { Suspense, useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonImg,
  IonInput,
  IonLabel,
  IonNote,
  IonPage,
  IonRow,
  IonSkeletonText,
  IonSpinner,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { arrowBack, close } from "ionicons/icons";
import ApplicationContext from "../Context";
import PaymentDetail from "../components/PaymentDetail";
import PaymentTotal from "../components/PaymentTotal";
import "../styles/views/confirmation-order.css";

export default function ConfirmationOrder() {
  const { authentication, checkout, code, promo, transaction } = useContext(ApplicationContext);
  const history = useHistory();
  const [inputName, setInputName] = useState("");
  const [inputPhoneNumber, setInputPhoneNumber] = useState("");
  const [errorPhoneNumber, setErrorPhoneNumber] = useState<string>();
  const inputNameElement = useRef<HTMLIonInputElement>(null);
  const inputPhoneNumberElement = useRef<HTMLIonInputElement>(null);

  useEffect(() => {
    if (authentication.phoneNumber?.length === 0) {
      setInputPhoneNumber("");
    } else if (authentication.customerName?.length === 0) {
      setInputName("");
    } else {
      setInputPhoneNumber(authentication.phoneNumber);
      setInputName(authentication.customerName);
    }
  }, [authentication.phoneNumber, authentication.customerName]);

  const onInputName = (event: Event) => {
    const value = (event.target as HTMLIonInputElement).value as string;
    const filteredValue = value.replace(/[^a-zA-Z()',./ ]+/g, "");
    setInputName(filteredValue);
    authentication.setCustomerName(filteredValue);
    if (inputNameElement.current !== null) {
      inputNameElement.current.value = filteredValue;
    }
  };

  const onInputPhoneNumber = (event: Event) => {
    const value = (event.target as HTMLIonInputElement).value as string;
    const filteredValue = value.replace(/[^0-9#*+]+/g, "");

    setInputPhoneNumber(filteredValue);
    authentication.setPhoneNumber(filteredValue);
    setErrorPhoneNumber((previousState) => (filteredValue !== "" ? undefined : previousState));
    if (inputPhoneNumberElement.current !== null) {
      inputPhoneNumberElement.current.value = filteredValue;
    }
  };

  const handleInputPromoCode = async () => {
    if (inputPhoneNumber?.length > 8 && inputPhoneNumber?.length < 13) {
      await authentication.handle.check(inputPhoneNumber, (register) => {
        if (register) authentication.handle.request(inputName, inputPhoneNumber);
      });
      history.push(`/${code}/promo-code`);
    } else if (inputPhoneNumber?.length > 0 && inputPhoneNumber?.length < 8) {
      setErrorPhoneNumber("Nomor HP minimal 8 digit");
    } else if (inputPhoneNumber?.length > 13) {
      setErrorPhoneNumber("Nomor HP maksimal 13 digit");
    } else {
      await authentication.handle.check(inputPhoneNumber, (register) => {
        if (register) authentication.handle.request(inputName, inputPhoneNumber);
      });
      history.push(`/${code}/promo-code`);
    }
  };

  const handleSelectPayments = async () => {
    if (checkout.state.grandtotal === 0) {
      await transaction.handle.new("Cash", "Cash", async (id, redirect) => {
        if (!redirect) {
          history.replace(`/${code}/qr-code?id_transaction=${id}`);
        }
      });
    } else if (inputName === "" && inputPhoneNumber === "") {
      history.push(`/${code}/payment-method`);
    } else {
      if (inputPhoneNumber?.length > 8 && inputPhoneNumber?.length < 13) {
        await authentication.handle.check(inputPhoneNumber, (register) => {
          if (register) authentication.handle.request(inputName, inputPhoneNumber);
          history.push(`/${code}/payment-method`);
        });
      } else if (inputPhoneNumber?.length > 0 && inputPhoneNumber?.length < 8) {
        setErrorPhoneNumber("Nomor HP minimal 8 digit");
      } else if (inputPhoneNumber?.length > 13) {
        setErrorPhoneNumber("Nomor HP maksimal 13 digit");
      } else {
        await authentication.handle.check(inputPhoneNumber, (register) => {
          if (register) authentication.handle.request(inputName, inputPhoneNumber);
          history.push(`/${code}/payment-method`);
        });
      }
    }
  };

  return (
    <IonPage className="view-confirmation-order">
      <IonHeader mode="md">
        <IonToolbar mode="md">
          <IonButtons slot="start">
            <IonBackButton defaultHref={`/${code}`} icon={arrowBack} mode="md" />
          </IonButtons>
          <IonTitle>
            <Suspense fallback={<IonSkeletonText animated={true} />}>
              <IonImg alt={process.env.REACT_APP_NAME} src="assets/images/logo.png" title={process.env.REACT_APP_NAME} />
            </Suspense>
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <IonGrid className="ion-margin-bottom">
          <IonRow>
            <IonCol size="12">
              <IonText mode="md">
                <h2>KONFIRMASI ORDER</h2>
              </IonText>
              <IonCard mode="md">
                <IonCardContent mode="md">
                  <IonGrid style={{ "--ion-grid-padding": 0 }}>
                    <IonRow>
                      <IonCol size="12" sizeMd="4" style={{ "--ion-grid-column-padding": "0.5rem" }}>
                        <IonLabel mode="md">
                          <label htmlFor="name">Nama</label>
                        </IonLabel>
                        <IonInput
                          autofocus
                          aria-label="name"
                          id="name"
                          mode="md"
                          placeholder="Nama"
                          name="name"
                          onIonInput={onInputName}
                          ref={inputNameElement}
                          class="ion"
                          style={{ minHeight: "2.75rem" }}
                          value={inputName}
                        />
                      </IonCol>
                      <IonCol size="12" sizeMd="4" style={{ "--ion-grid-column-padding": "0.5rem" }}>
                        <IonLabel mode="md">
                          <label htmlFor="phone-number">No HP</label>
                        </IonLabel>
                        <IonInput
                          id="phone-number"
                          mode="md"
                          name="phone-number"
                          onIonInput={onInputPhoneNumber}
                          placeholder="08XX XXXX XXXX"
                          required={true}
                          ref={inputPhoneNumberElement}
                          style={{ minHeight: "2.75rem" }}
                          value={inputPhoneNumber}
                        />
                        {errorPhoneNumber ? <IonNote color="danger">{errorPhoneNumber}</IonNote> : null}
                      </IonCol>
                      <IonCol size="12" sizeMd="4" style={{ "--ion-grid-column-padding": "0.5rem" }}>
                        <IonLabel mode="md">
                          <label htmlFor="outlet">Outlet</label>
                        </IonLabel>
                        <IonInput id="outlet" mode="md" name="outlet" readonly style={{ minHeight: "2.75rem" }} value={checkout.state.outlet.name} />
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonGrid className="ion-margin-bottom">
          <IonRow className="ion-align-items-end">
            <IonCol size="12" sizeSm="6">
              <IonText mode="md">
                <h2>KODE PROMO</h2>
              </IonText>
              <IonButton className="promo-code" color="dark" expand="block" fill="clear" mode="md" onClick={handleInputPromoCode} strong={true}>
                {promo.code ? "Ubah" : "Input"} Kode Promo
              </IonButton>
            </IonCol>
            {promo.code ? (
              <IonCol className="current-promo-code" size="12" sizeSm="6">
                <IonCard mode="md">
                  <IonCardContent className="ion-text-center" mode="md">
                    {promo.code}
                  </IonCardContent>
                </IonCard>
                <IonButton
                  color="danger"
                  mode="md"
                  onClick={async () => {
                    await promo.handle.cancel();
                  }}
                  strong={true}
                >
                  {promo.loading ? <IonSpinner name="crescent" slot="start" /> : <IonIcon slot="icon-only" icon={close}></IonIcon>}
                </IonButton>
              </IonCol>
            ) : null}
          </IonRow>
        </IonGrid>
        <IonGrid className="ion-margin-bottom">
          <IonRow>
            <IonCol size="12">
              <IonText mode="md">
                <h2>RINCIAN PEMBAYARAN</h2>
              </IonText>
              <IonCard mode="md">
                <IonCardContent mode="md">
                  {checkout.state.services?.length !== 0 ? (
                    <PaymentDetail
                      category="Services"
                      currency={checkout.state.currency}
                      data={checkout.state.services.map((service) => ({
                        date: service.date_display,
                        name: service.name,
                        price: service.price_total,
                        quantity: service.quantity,
                      }))}
                      subTotal={checkout.state.subtotal_services}
                    />
                  ) : null}
                  {checkout.state.products?.length !== 0 ? (
                    <PaymentDetail
                      category="Products"
                      currency={checkout.state.currency}
                      data={checkout.state.products.map((product) => ({ name: product.name, price: product.price_total, quantity: product.quantity }))}
                      subTotal={checkout.state.subtotal_products}
                    />
                  ) : null}
                  <PaymentTotal detail={checkout.state.payment_detail} grandTotal={checkout.state.grandtotal} />
                </IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonGrid className="submit">
          <IonRow className="ion-justify-content-center">
            <IonCol size="12" sizeMd="4" sizeSm="6">
              <IonButton color="dark" expand="block" id="open-modal-qr-code" mode="md" strong={true} onClick={handleSelectPayments}>
                {checkout.state.grandtotal === 0 ? "Order" : "Pilih Pembayaran"}
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
}
